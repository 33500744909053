import { XF } from "./XF";
import { convertUTCSecondsToRelative } from "./common/helper";

const secondsInMinute = 60;
const secondsInHour = 60 * secondsInMinute;
const secondsInDay = 24 * secondsInHour;

let localLoadTime: number;
let serverLoadTime: number;
let todayStart: number;
let todayDow: number;
let tomorrowStart: number;
let initialized = false;
let intervalRefresh: number | undefined;

function startInterval() {
  window.clearInterval(intervalRefresh);
  intervalRefresh = window.setInterval(() => {
    refresh();
  }, 20 * 1000);
}

function initialize() {
  if (initialized) {
    return;
  }
  initialized = true;

  const { time } = XF.config;

  localLoadTime = XF.getLocalLoadTime();
  serverLoadTime = time.now;
  todayStart = time.today;
  todayDow = time.todayDow;
  tomorrowStart = todayStart + secondsInDay;

  if (document.hidden !== undefined) {
    if (!document.hidden) {
      startInterval();
    }

    $(document).on("visibilitychange", () => {
      if (document.hidden) {
        window.clearInterval(intervalRefresh);
        intervalRefresh = undefined;
      } else {
        startInterval();
        refresh();
      }
    });
  } else {
    startInterval();
  }
}

function refresh() {
  if (!initialized) {
    initialize();
  }

  const $els = $(document).find("time[california-data-time]");
  const { length } = $els;
  const now = Math.floor(new Date().getTime() / 1000);
  const openLength = now - localLoadTime;

  if (serverLoadTime + openLength > todayStart + secondsInDay) {
    // day has changed, need to adjust
    const dayOffset = Math.floor(
      (serverLoadTime + openLength - todayStart) / secondsInDay
    );

    todayStart += dayOffset * secondsInDay;
    todayDow = (todayDow + dayOffset) % 7;
    tomorrowStart = todayStart + secondsInDay;
  }

  for (let i = 0; i < length; i++) {
    const el = $els[i] as HTMLElement & { xfDynType?: string };
    const thisTime = parseInt(
      el.getAttribute("california-data-time") || "",
      10
    );
    const interval = serverLoadTime - thisTime + openLength;
    const dynType = el.xfDynType;
    if (interval < -2) {
      const futureInterval = thisTime - (serverLoadTime + openLength);

      if (futureInterval < secondsInMinute * 15) {
        if (dynType !== "futureMoment") {
          el.textContent = XF.phrase("in_a_moment");
          el.xfDynType = "futureMoment";
        }
      } else if (futureInterval < secondsInHour) {
        const minutes = Math.round(futureInterval / secondsInMinute);
        if (dynType !== `futureMinutes${minutes}`) {
          el.textContent = XF.phrase("in_x_minutes", {
            "{minutes}": minutes,
          });
          el.xfDynType = `futureMinutes${minutes}`;
        }
      } else if (thisTime < tomorrowStart) {
        if (dynType !== "latertoday") {
          el.textContent = XF.phrase("later_today_at_x", {
            "{time}": getTwelveHourTime(thisTime),
          });
          el.xfDynType = "latertoday";
        }
      } else if (thisTime < tomorrowStart + secondsInDay) {
        if (dynType !== "tomorrow") {
          el.textContent = XF.phrase("tomorrow_at_x", {
            "{time}": el.dataset.timeString || "",
          });
          el.xfDynType = "tomorrow";
        }
      } else if (futureInterval < 7 * secondsInDay) {
        el.xfDynType = "future";
      } else {
        if (el.dataset.fullDate) {
          el.textContent = XF.phrase("date_x_at_time_y", {
            "{date}": el.dataset.dateString || "", // must use attr for string value
            "{time}": el.dataset.timeString || "", // must use attr for string value
          });
        } else {
          el.textContent = el.dataset.dateString || ""; // must use attr for string value
        }
        el.xfDynType = "future";
      }
    } else {
      el.textContent = convertUTCSecondsToRelative(thisTime);
    }
  }
}

function getTwelveHourTime(timeStamp: number) {
  const dt = new Date(timeStamp * 1000);
  let hours = dt.getHours();
  let minutes: string | number = dt.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
}

refresh();
