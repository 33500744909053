import {
  differenceInDays,
  differenceInMinutes,
  format,
  formatDistanceToNowStrict,
  isValid,
} from "date-fns";
import { XF } from "../XF";

export const DESKTOP_1600 = 1600;
export const DESKTOP_1054 = 1054;
export const TABLET_900 = 900;
export const MOBILE_732 = 732;
export const MOBILE_640 = 640;

export const VISIBLE_FEATURED_SLOTS_ON_DESKTOP = 5;
export const VISIBLE_FEATURED_SLOTS_ON_TABLET = 3;
export const VISIBLE_FEATURED_SLOTS_ON_MOBILE = 2;

export function imageHasExtension(imageUrl: string, extension: string) {
  const regex = new RegExp(`.+(${extension})[.d*/]*$`);
  return regex.test(imageUrl);
}

function isImageExternal(imageUrl: string) {
  const siteID = window.location.hostname.replace(/^www\./, "");
  const absoluteRegex = /^\//;
  const internalImageRegex = new RegExp(`(^.*://)?((www\\.)?${siteID}).*`);

  const isAbsolute = absoluteRegex.test(imageUrl);
  const isImageHostedInternally = internalImageRegex.test(imageUrl);

  return !(isAbsolute || isImageHostedInternally);
}

export function convertUTCSecondsToDateTime(time: number) {
  if (!isValid(time)) return "Invalid date";
  return format(time * 1000, "MMM d, yyyy @ hh:mm a").replace("@", "at");
}

export function convertUTCSecondsToDate(time: number) {
  if (!isValid(time)) return "Invalid date";
  return format(time * 1000, "MMM d, yyyy");
}

export function convertUTCSecondsToTime(time: number) {
  if (!isValid(time)) return "Invalid date";
  return format(time * 1000, "hh:mm a");
}

/**
 * Converts provided time in seconds to relative time
 * Note: Must be implemented to the same spec as `Common::getRelativeTime()` in PHP
 */
export function convertUTCSecondsToRelative(time: number) {
  if (!isValid(time)) return "Invalid date";

  const currentDate = new Date();
  const date = new Date(time * 1000);

  // absolute date boundaries
  if (differenceInMinutes(currentDate, date) < 15)
    return XF.phrase("california_a_moment_ago");
  if (differenceInDays(currentDate, date) > 7)
    return format(date, "MMM d, yyyy");

  // relative dates
  const relative = formatDistanceToNowStrict(date, { addSuffix: true });
  const [, value, unit] =
    relative.match(/(\d+)\s(minute|hour|day|month)s?/) || [];
  switch (unit) {
    case "minute":
      return XF.phrase("california_x_mins_ago", { "{mins}": value });
    case "hour":
      return XF.phrase("california_x_hours_ago", { "{hours}": value });
    case "day":
      return XF.phrase("california_x_days_ago", { "{days}": value });
    case "month":
      return XF.phrase("california_x_months_ago", { "{months}": value });
    default:
      return relative;
  }
}

export function getCFSrc(size: number, imageUrl: string) {
  if (imageHasExtension(imageUrl, "gif") || isImageExternal(imageUrl))
    return imageUrl;
  return `/cdn-cgi/image/format=auto,onerror=redirect,width=${size},height=${size},fit=scale-down/${imageUrl}`;
}

/** @returns a user-friendly formatted number, with thousands/millions shorthand */
export function shortenNumber(number: number) {
  if (number < 1000) {
    return formatNumber(number);
  }

  if (number < 1000000) {
    return `${formatNumber(Math.round(number / 100) / 10)}K`;
  }

  return `${formatNumber(Math.round(number / 100000) / 10)}M`;
}

/** @returns a user-friendly formatted number */
export function formatNumber(number: number) {
  return number.toLocaleString("us", {
    useGrouping: true,
  });
}

/**
 * Retrieve browser width, there is no agreed upon way to dynamically retrieve browser width in JS,
 * different browsers do not consistently provide the same values. window.innerWidth appears to be the
 * standard browsers are moving towards, the other two values are fallbacks.
 */
export function getDeviceWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
}
